import { default as _slugify } from 'slugify'
import { DateTime } from 'luxon'
import isPlainObject from 'lodash/isPlainObject'
import merge from 'lodash/merge'
import validator from 'validator'
import type { Job } from 'api/types'

export const isServer = typeof window === 'undefined'

export function slugify(input: string) {
  return _slugify(input, {
    lower: true,
    trim: true
  })
}

export function toFixed(value: number, fractionDigits: number): string {
  return value.toFixed(fractionDigits).replace(/\.?0+$/gi, '')
}

export function formatDate(
  value: Date | string,
  format: string,
  options?: { zone?: string }
): string {
  const date = new Date(value)
  return DateTime.fromJSDate(date, options).toFormat(format)
}

export function flattenObject(
  _obj: Record<string, any>,
  _path: string[] = []
): Record<string, any> {
  const _flattenObject = (obj: Record<string, any>, path: string[]): Record<string, any> => {
    return !isPlainObject(obj)
      ? { [path.join('.')]: obj }
      : Object.entries(obj).reduce(
          (acc, [key, value]) => merge(acc, _flattenObject(value, [...path, key])),
          {}
        )
  }
  return _flattenObject(_obj, _path)
}

export function applicationLink(job: Job): string {
  const link = job.applicationLink.trim()
  if (validator.isEmail(link)) {
    const subject = encodeURIComponent(`Application: ${job.title}`)
    return `mailto:${link}?subject=${subject}`
  }
  if (link.startsWith('http://') || link.startsWith('https://')) {
    return link
  }
  return `http://${link}`
}
