import { isServer } from 'lib/utils'

export function setItem(key: string, value: any | null) {
  if (isServer) {
    return
  }
  if (value === null) {
    window?.localStorage?.removeItem(key)
    return
  }
  window?.localStorage?.setItem(key, JSON.stringify(value))
}

export function getItem<T>(key: string): T | null {
  if (isServer) {
    return null
  }
  const value = window?.localStorage?.getItem(key)
  return value ? JSON.parse(value) : null
}
